import type { AppProps } from "next/app";
import Head from "next/head";
import {
  Inter as FontSans,
  STIX_Two_Text as FontSerif,
} from "next/font/google";
import "@/styles/tailwind.css";
import "focus-visible";

const fontSans = FontSans({
  subsets: ["latin"],
});

const fontSerif = FontSerif({
  subsets: ["latin"],
});

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <style jsx global>
        {`
          :root {
            --font-inter: ${fontSans.style.fontFamily};
            --font-stix-two-text: ${fontSerif.style.fontFamily};
          }
        `}
      </style>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* <meta property="og:image" content={gfcLogo.src} /> */}
      </Head>
      <Component {...pageProps} />
    </>
  );
}
